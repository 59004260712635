<template>
  <div class="project-notes-container">
    <breadcrumbs :sourceLink="sourceLink" :sourceName="projectName" currentName="Project's Notes" />
    <div class="md-layout">
      <div class="md-layout-item md-size-100 filters-container filters-container-margin">
        <div class="filtering-card">
          <div class="md-layout md-alignment-bottom-center">
            <div class="md-layout-item md-layout md-size-100">
              <!-- Filter by Name -->
              <div class="md-layout-item md-size-33 ">
                <!-- <label class="md-form-label">Filter by Name</label> -->
                <md-field>
                  <md-input
                    v-model="filters.content"
                    placeholder="Search in note's text"
                    type="search"
                    class="text-search-input"
                    clearable
                  />
                </md-field>
              </div>
            </div>
            <!--
            <div class="md-layout-item md-layout md-size-10 md-alignment-bottom-center">
              <div class="md-layout-item md-size-100">
                <md-button class="md-info md-sm" @click="resetSearch">Reset</md-button>
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading">
      <div
        v-if="projectHaveNotes"
        class="md-layout gutter-small"
        @keydown.ctrl.down.prevent.stop="focusNoteDown"
        @keydown.meta.down.prevent.stop="focusNoteDown"
        @keydown.ctrl.up.prevent.stop="focusNoteUp"
        @keydown.meta.up.prevent.stop="focusNoteUp"
        @keydown.ctrl.left.prevent.stop="focusNoteLeft"
        @keydown.meta.left.prevent.stop="focusNoteLeft"
        @keydown.ctrl.right.prevent.stop="focusNoteRight"
        @keydown.meta.right.prevent.stop="focusNoteRight"
      >
        <div
          v-for="(notesColumn, columnNumber) in notesSplitArray"
          :key="'column' + columnNumber"
          class="md-layout-item"
          :class="columnSize"
        >
          <div
            v-for="(note, lineNumber) in notesColumn"
            :key="note.id"
            class="note-container"
          >
            <note
              :note="note"
              @hasBeenFocused="haveBeenFocused((columnNumber * nbNotePerColumn) + lineNumber)"
              :isFocused="isNoteFocused((columnNumber * nbNotePerColumn) + lineNumber)"
              @saveNote="onSaveNote"
              @deleteNote="onDeleteNote"
              :hideRelatedElementInfo="false"
              :ref="notes"
              :hideInfo="false"
            />
          </div>
        </div>
      </div>
      <div v-if="!projectHaveNotes" class="no-note-info-container">
        <div class=no-note-info>
          <md-icon class="note-icon">list</md-icon>
          <h2>No notes found</h2>
        </div>
      </div>
    </div>
    <div v-if="loading">
      <loader />
    </div>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import notify from '@/utils/notificationsUtils';
import api from '@/api/api';
import Note from '@/components/Avalia/Note/Note.vue';
import Breadcrumbs from '@/components/Avalia/Breadcrumbs/Breadcrumbs.vue';
import Loader from '@/components/Avalia/Loader/Loader.vue';

export default {
  components: {
    Note,
    Breadcrumbs,
    Loader,
  },
  data() {
    return {
      loading: true,
      focusedNoteIndex: null,
      projectId: null,
      notes: [],
      noteFocus: null,
      filters: {
        content: '',
      },
      nbColumns: 1,
    };
  },
  computed: {
    columnSize() {
      const sizeInPercent = Math.floor(100 / this.nbColumns);
      return `md-size-${sizeInPercent}`;
    },
    projectHaveNotes() {
      return this.notes.length > 0;
    },
    notesSplitArray() {
      const result = [];
      let columnNote = [];

      for (let line = 0; line < this.nbColumns; line++) {
        for (let i = 0; i < this.nbNotePerColumn; i++) {
          const value = this.notes[i + line * this.nbNotePerColumn];
          if (!value) continue; //avoid adding "undefined" values
          columnNote.push(value);
        }
        result.push(columnNote);
        columnNote = [];
      }
      return result;
    },
    firstThirdNotes() {
      return this.notesSplitArray[0];
    },
    secondThirdNotes() {
      return this.notesSplitArray[1];
    },
    thirdThirdNotes() {
      return this.notesSplitArray[2];
    },
    sourceLink() {
      return `/project/${this.projectId}`;
    },
    projectName() {
      return this.$store.getters['project/GET_PROJECT_NAME'];
    },
    nbNotePerColumn() {
      const nbNotes = this.notes.length;
      return Math.ceil(nbNotes / this.nbColumns);
    },
  },
  watch: {
    'filters.content': function (value) {
      this.fetchNotes();
    },
    '$route.params.projectId': {
      handler(newValue, oldValue) {
        this.projectId = +this.$route.params.projectId;
        this.fetchNotesOnInit();
      },
      immediate: false,
    },
  },
  created() {
    this.projectId = +this.$route.params.projectId;
    this.fetchNotesOnInit();
  },
  mounted() {
    this.computeNbColumns();

    window.onresize = () => {
      this.computeNbColumns();
    };
  },
  methods: {
    fetchNotesOnInit() {
      this.loading = true;
      this.fetchNotesQuery()
        .then(() => {
          this.loading = false;
        });
    },
    fetchNotes: debounce(function deb() {
      this.fetchNotesQuery();
    }, 250),
    fetchNotesQuery() {
      let url = `/projects/${this.projectId}/notes?includeTaskNotes=true`;
      if (this.filters.content && this.filters.content.length > 0) {
        url += `&content=${this.filters.content}`;
      }
      return api.assess
        .get(url)
        .then((response) => {
          const taskNotes = response.data;
          this.notes = taskNotes;
          this.computeNbColumns();
        })
        .catch((error) => {
          // console.log(error);
          const errorMessage = `Could not Fetch Task Notes : ${error.response.data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    onSaveNote(notePayload) {
      api.assess
        .put(`/notes/${notePayload.id}`, notePayload)
        .then((response) => {
          this.fetchNotes();
          const successMessage = 'Note successfully Updated';
          notify.notifySuccess(successMessage, this);
        })
        .catch((error) => {
          const errorMessage = `Could not Update Note for Project ${this.projectName} : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    onDeleteNote(notePayload) {
      api.assess
        .delete(`/notes/${notePayload.id}`)
        .then((response) => {
          this.fetchNotes();
          const successMessage = 'Note successfully Deleted';
          notify.notifySuccess(successMessage, this);
        })
        .catch((error) => {
          const errorMessage = `Could not Delete Note for Project ${this.projectName} : ${error.response
            .data.message || ''}`;
          notify.notifyError(errorMessage, this);
        });
    },
    haveBeenFocused(index) {
      this.focusedNoteIndex = index;
    },
    isNoteFocused(index) {
      return this.focusedNoteIndex === index;
    },
    computeNbColumns() {
      const windowsWidth = window.innerWidth;
      const mdSmall = 599;
      const mdMedium = 959;
      let nbColumns = 1;
      if (windowsWidth >= mdSmall && this.notes.length > 1) {
        nbColumns = 2;
      }
      if (windowsWidth >= mdMedium && this.notes.length > 4) {
        nbColumns = 3;
      }
      this.nbColumns = nbColumns;
    },
    focusNoteUp() {
      if (this.focusedNoteIndex >= 0) {
        this.focusedNoteIndex -= 1;
      }
    },
    focusNoteDown() {
      if (this.focusedNoteIndex < this.notes.length - 1) {
        this.focusedNoteIndex += 1;
      }
    },
    focusNoteLeft() {
      if (this.focusedNoteIndex - this.nbNotePerColumn >= 0) {
        this.focusedNoteIndex -= this.nbNotePerColumn;
      }
    },
    focusNoteRight() {
      if (this.focusedNoteIndex + this.nbNotePerColumn < this.notes.length) {
        this.focusedNoteIndex += this.nbNotePerColumn;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.project-notes-container {
  padding-left: 15px;
}
.note-container {
  margin-bottom: 10px;
}
.no-note-info-container {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.no-note-info-container {
  text-align: center;
}
.note-icon {
  font-size: 150px!important;
  margin-bottom: 50px;
}
.filters-container-margin {
  margin-top: 30px;
}
</style>
